@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

/* Fonts */
$font: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

/* Wrapper */
$horizontal-padding: 2rem;
$horizontal-padding-small: 5%;
$max-width: 60rem;
$header-height: 60px;

/* Border Radius */
$border-radius-small: 4px;
$border-radius-medium: 6px;
$border-radius-large: 12px;
$border-radius-max: 999px;

:root {
  /* Fonts */
  --main-font: #{$font};

  /* Wrapper */
  --horizontal-padding: #{$horizontal-padding};
  --max-width: #{$max-width};
  --header-height: #{$header-height};

  /* Border Radius */
  --border-radius-small: #{$border-radius-small};
  --border-radius-medium: #{$border-radius-medium};
  --border-radius-large: #{$border-radius-large};
  --border-radius-max: #{$border-radius-max};

  @media (max-width: 500px) {
    --horizontal-padding: #{$horizontal-padding-small};
  }
}
