.button {
  appearance: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--main-font);
  border: 1px solid var(--dark);
  outline: none;
  background-color: var(--dark);
  color: #fff;
  height: 38px;
  line-height: 36px;
  padding: 0 1.35rem;
  max-width: 100%;
  border-radius: var(--border-radius-small);
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s;

  @media (hover: hover) {
    &:hover {
      background-color: #fff;
      color: var(--dark);

      svg {
        filter: invert(1);
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #fafafa;
    color: #999;
    border: 1px solid #eaeaea;
  }
}

.loading {
  color: #fafafa !important;

  svg {
    opacity: 0;
  }
}

.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.prefix,
.suffix {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    width: 24px;
    height: 24px;
  }
}

.prefix {
  margin-right: 14px;
}
.suffix {
  margin-left: 14px;
}
