.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    margin: 0 1px;
    width: 5px;
    height: 5px;
    background-color: #444;
    border-radius: 50%;
    animation: blink 1.4s infinite both;

    &:nth-child(2) {
      animation-delay: 0.175s;
    }
    &:nth-child(3) {
      animation-delay: 0.35s;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.25;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.small {
  span {
    width: 5px;
    height: 5px;
  }
}

.medium {
  span {
    width: 7px;
    height: 7px;
  }
}

.large {
  span {
    margin: 0 2px;
    width: 10px;
    height: 10px;
  }
}
