.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--header-height) - 8rem);
  min-height: 28rem;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-medium);
  width: 100%;
  max-width: 26rem;
  margin: 0 auto;

  @media (max-width: 350px) {
    padding: 2.25rem;
  }
}

.providers {
  margin: 2rem auto 0.5rem;
  max-width: 100%;
}
