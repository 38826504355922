.tabs {
  z-index: 100;
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #fafafa;

  & > ul {
    list-style: none;
    display: flex;
    overflow: auto;
    white-space: nowrap;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0.75rem var(--horizontal-padding);
    scrollbar-width: 0px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  button {
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    appearance: none;
    background: none;
    box-shadow: none;
    border: none;
    padding: 0.25rem 0;

    &.active {
      pointer-events: none;
      font-weight: 600;
    }
  }
}
