.transactions {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 1.5rem;
}

.tabs {
  margin: 0 0 1.5rem;

  ul {
    position: relative;
    list-style: none;
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: 0px;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      &:not(:last-child) {
        margin-right: 0.75rem;
      }

      button {
        cursor: pointer;
        appearance: none;
        background: none;
        box-shadow: none;
        border: 1px solid var(--light-7);
        border-radius: var(--border-radius-small);
        padding: 0.5rem 1.5rem;
        white-space: nowrap;

        opacity: 0.4;
        transition: opacity 0.25s;

        @media (hover: hover) {
          &:hover {
            opacity: 0.9;
          }
        }
      }

      &.active {
        button {
          opacity: 1;
          pointer-events: none;
          border-color: var(--dark-5);
        }
      }
    }
  }
}
