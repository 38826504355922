$bp: 500px;

.header {
  z-index: 99;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
  background-color: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
}

.inner {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--max-width);
  padding: 0 var(--horizontal-padding);
}

.title {
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
  color: #000;
  margin-right: 2%;

  @media (max-width: $bp) {
    display: none;
  }
}

.select {
  margin-left: auto;
  margin-right: 2%;
  @media (max-width: $bp) {
    margin-left: inherit;
    margin-right: var(--horizontal-padding);
    flex: 1;
  }
}
