.transaction {
  &:not(:last-child) {
    margin-bottom: 0.65rem;
  }
}

.header {
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 1.25rem;
  border-radius: var(--border-radius-small);
  border: 1px solid #eaeaea;
  box-shadow: var(--shadow-small);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: #fff;
  transition: background-color 0.15s;

  @media (hover: hover) {
    &:hover {
      background-color: #fafafa;
    }
  }
}

.icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  svg {
    width: 24px;
    height: 24px;
  }
}

.name {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount {
  font-weight: 600;
  margin-left: auto;
  padding-left: 2rem;

  @media (max-width: 750px) {
    padding-left: 1.75rem;
  }
}

.time {
  cursor: default;
  font-size: 0.75rem;
  min-width: 7.25rem;
  text-align: right;
  color: #888;

  @media (max-width: 750px) {
    display: none;
  }
}

.details {
  padding: 1.3rem 1.25rem 1rem;
  margin-top: -4px;
  border: 1px solid #eaeaea;
  border-top: none;
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-small);
  color: #555;

  & > div {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;

    & > *:last-child {
      margin-left: 1rem;
      max-width: 60%;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.date {
  max-width: none !important;
  white-space: normal !important;
}
