.input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.container {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.toggle {
  --toggle-height: 23px;
  --toggle-gap: calc(var(--toggle-height) / 6.75);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(var(--toggle-height) * 1.75);
  height: var(--toggle-height);
  border-radius: 4px;
  background-color: var(--light-6);
  transition: background-color 0.2s;

  > span {
    position: absolute;
    left: var(--toggle-gap);
    height: calc(var(--toggle-height) - var(--toggle-gap) * 2);
    width: calc(var(--toggle-height) - var(--toggle-gap) * 2);
    border-radius: 3px;
    background-color: var(--light);
    transition: left 0.2s;
  }

  &.active {
    background-color: var(--dark);
    > span {
      left: calc(100% - var(--toggle-height) + var(--toggle-gap));
    }
  }
}

.label {
  margin-left: 1rem;
}
