$bp: 750px;

.container {
  display: flex;
  flex-direction: column;
}

.inputs {
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;

  > * {
    flex: 1;

    &:first-child {
      flex: 1 1 15%;
    }
  }

  @media (max-width: $bp) {
    flex-direction: column;
  }
}

.date {
  position: relative;

  .picker {
    --rdp-accent-color: #000;
    --rdp-background-color: #f1f1f1;
    --rdp-accent-color-dark: #fff;
    --rdp-background-color-dark: #363636;
    --rdp-outline: 2px solid var(--rdp-accent-color);

    top: 100%;
    left: 0;
    margin-top: 1rem;
    position: absolute;
    border: 1px solid var(--light-7);
    border-radius: var(--border-radius-small);
    box-shadow: var(--shadow-medium);

    background-color: var(--light);
  }
}

.config {
  display: flex;
  align-items: center;
  border: 1px solid var(--light-8);
  border-radius: var(--border-radius-small);
  padding: 0 0.75rem;

  > div {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: $bp) {
    padding: 0.55rem 0.75rem;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.175rem;
}

.exportActions {
  display: flex;
  flex: 1;
  gap: 1rem;
}

.button {
  height: 46px;
  flex: 1;
}

.landlord {
  margin-bottom: 1.75rem;
}

.reservations {
  list-style: none;

  & > *:not(:last-child) {
    margin-bottom: 0.65rem;
  }
}

.reservationContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.875rem 1.25rem;
  border-radius: var(--border-radius-small);
  border: 1px solid #eaeaea;
  box-shadow: var(--shadow-small);

  background-color: #fff;
  transition: background-color 0.15s;

  @media (hover: hover) {
    &:hover {
      background-color: #fafafa;
    }
  }
}

.reservation {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .id {
    margin-right: 0.5rem;
  }

  .info {
    opacity: 0.5;
    font-size: 0.9rem;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 1rem;
  }

  .reservationActions {
    flex-shrink: 0;
    margin-left: auto;
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 0.825rem;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.65;

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }
  }

  .indicator {
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--color-red);
    border-radius: 50%;

    &.export {
      background-color: var(--color-green);
    }
  }
}

.additionalGuests {
  pointer-events: none;
  font-size: 0.75rem;
  padding-right: 4rem;
  opacity: 0.5;
}
