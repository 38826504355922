.box {
  padding: 1.5rem 2rem;
  border-radius: var(--border-radius-small);
  background-color: var(--color-box-grey);
  text-align: center;
  width: 100%;
  margin-bottom: 1.5rem;
  color: var(--dark);

  a {
    color: var(--dark);
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1px solid var(--dark);
    opacity: 1;

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.75;
    }
  }
}

.error,
.success {
  composes: box;
  color: #fff;
  a {
    color: #fff;
    border-color: #fff;
  }
}

.error {
  background-color: var(--color-red);
}

.success {
  background-color: var(--color-green);
}
