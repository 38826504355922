.name {
  width: 100%;

  span {
    span {
      width: 100% !important;
      max-width: 10rem;
    }
  }
}
