html,
body {
  font-family: var(--main-font);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #000;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
button,
select,
textarea,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

p {
  margin: 0;
  line-height: 1.6;
  font-weight: 400;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
}

button,
input,
textarea,
select {
  font-size: 1rem;
  color: var(--dark);
}

input[type='text'],
input[type='number'],
input[type='search'],
input[type='password'],
input[type='tel'],
input[type='datetime-local'],
input[type='color'],
input[type='url'],
textarea {
  background-color: var(--light);
  color: var(--dark);
  font-family: inherit;
  font-size: 1rem;
  padding: 0.75rem;
  appearance: none;
  box-shadow: none;
  border: 1px solid var(--light-8);
  border-radius: var(--border-radius-small);
  width: 100%;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;

  &:hover {
    border-color: var(--light-9);
  }

  &:focus,
  &:focus-within {
    border: 1px solid var(--dark);
    box-shadow: inset 0 0 0 1px var(--dark);
  }

  /* Change the white to any color */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 99px var(--light-3) inset !important;
    color: var(--dark) !important;
    -webkit-text-fill-color: var(--dark) !important;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    border: 1px solid var(--light-7);
  }
}

textarea {
  min-width: 100%;
  max-width: 100%;
  line-height: 1.4;

  -ms-overflow-style: none;
  scrollbar-width: 0px;
  &::-webkit-scrollbar {
    display: none;
  }
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

label > span {
  display: inline-block;
  opacity: 0.5;
  font-size: 0.85rem;
  margin-bottom: 0.25rem;
}

svg:not(root) {
  fill: var(--dark-3);
}

[data-theme='dark'] {
  input,
  textarea {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 99px var(--light-2) inset !important;
    }
  }
}
