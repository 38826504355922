@use 'sass:color';

/* Signals */
$color-darkred: #a5081e;
$color-darkred-dark: color.scale($color-darkred, $lightness: -5%);
$color-darkred-light: color.scale($color-darkred, $lightness: 10%);

$color-red: #e21822;
$color-red-dark: color.scale($color-red, $lightness: -5%);
$color-red-light: color.scale($color-red, $lightness: 10%);

$color-yellow: #da9e45;
$color-yellow-dark: color.scale($color-yellow, $lightness: -5%);
$color-yellow-light: color.scale($color-yellow, $lightness: 10%);

$color-green: #4eb769;
$color-green-dark: color.scale($color-green, $lightness: -5%);
$color-green-light: color.scale($color-green, $lightness: 10%);

$color-violet: #7827c9;
$color-violet-dark: color.scale($color-violet, $lightness: -5%);
$color-violet-light: color.scale($color-violet, $lightness: 10%);

$color-purple: #f11fe0;
$color-purple-dark: color.scale($color-purple, $lightness: -5%);
$color-purple-light: color.scale($color-purple, $lightness: 10%);

$color-pink: #ff0080;
$color-pink-dark: color.scale($color-pink, $lightness: -5%);
$color-pink-light: color.scale($color-pink, $lightness: 10%);

$color-cyan: #4cdbbc;
$color-cyan-dark: color.scale($color-cyan, $lightness: -5%);
$color-cyan-light: color.scale($color-cyan, $lightness: 10%);

/* Background */
$background-color: #fff;

/* Accents */
$dark: #000;
$dark-2: #111;
$dark-3: #333;
$dark-4: #444;
$dark-5: #666;
$dark-6: #888;

$light: #fff;
$light-2: #fafafa;
$light-3: #f7f7f7;
$light-4: #f1f1f1;
$light-5: #eaeaea;
$light-6: #e2e2e2;
$light-7: #d7d7d7;
$light-8: #ccc;
$light-9: #aaa;

/* Shadows */
$shadow-small: 0 0 8px rgba(0, 0, 0, 0.075);
$shadow-medium: 0 0 24px rgba(0, 0, 0, 0.1);
$shadow-large: 0 0 64px rgba(0, 0, 0, 0.15);

/* Allocation to CSS variables */
:root {
  /* Signals */
  --color-darkred: #{$color-darkred};
  --color-darkred-dark: #{$color-darkred-dark};
  --color-darkred-light: #{$color-darkred-light};

  --color-red: #{$color-red};
  --color-red-dark: #{$color-red-dark};
  --color-red-light: #{$color-red-light};

  --color-yellow: #{$color-yellow};
  --color-yellow-dark: #{$color-yellow-dark};
  --color-yellow-light: #{$color-yellow-light};

  --color-green: #{$color-green};
  --color-green-dark: #{$color-green-dark};
  --color-green-light: #{$color-green-light};

  --color-violet: #{$color-violet};
  --color-violet-dark: #{$color-violet-dark};
  --color-violet-light: #{$color-violet-light};

  --color-purple: #{$color-purple};
  --color-purple-dark: #{$color-purple-dark};
  --color-purple-light: #{$color-purple-light};

  --color-pink: #{$color-pink};
  --color-pink-dark: #{$color-pink-dark};
  --color-pink-light: #{$color-pink-light};

  --color-cyan: #{$color-cyan};
  --color-cyan-dark: #{$color-cyan-dark};
  --color-cyan-light: #{$color-cyan-light};

  /* Background */
  --background-color: #{$background-color};

  /* Accents */
  --dark: #{$dark};
  --dark-2: #{$dark-2};
  --dark-3: #{$dark-3};
  --dark-4: #{$dark-4};
  --dark-5: #{$dark-5};
  --dark-6: #{$dark-6};

  --light: #{$light};
  --light-2: #{$light-2};
  --light-3: #{$light-3};
  --light-4: #{$light-4};
  --light-5: #{$light-5};
  --light-6: #{$light-6};
  --light-7: #{$light-7};
  --light-8: #{$light-8};
  --light-9: #{$light-9};

  /* Shadows */
  --shadow-small: #{$shadow-small};
  --shadow-medium: #{$shadow-medium};
  --shadow-large: #{$shadow-large};
}

/* Dark Theme */
$background-color-dark: #000;

$dark-dark: #fff;
$dark-2-dark: #fafafa;
$dark-3-dark: #eaeaea;
$dark-4-dark: #ddd;
$dark-5-dark: #ccc;
$dark-6-dark: #aaa;

$light-dark: #000;
$light-2-dark: #101010;
$light-3-dark: #151515;
$light-4-dark: #202020;
$light-5-dark: #252525;
$light-6-dark: #303030;
$light-7-dark: #444;
$light-8-dark: #555;
$light-9-dark: #777;

$shadow-small-dark: 0 0 8px rgba(80, 80, 80, 0.075);
$shadow-medium-dark: 0 0 24px rgba(80, 80, 80, 0.1);
$shadow-large-dark: 0 0 64px rgba(80, 80, 80, 0.15);

[data-theme='dark'] {
  /* Background */
  --background-color: #{$background-color-dark};

  /* Accents */
  --dark: #{$dark-dark};
  --dark-2: #{$dark-2-dark};
  --dark-3: #{$dark-3-dark};
  --dark-4: #{$dark-4-dark};
  --dark-5: #{$dark-5-dark};
  --dark-6: #{$dark-6-dark};

  --light: #{$light-dark};
  --light-2: #{$light-2-dark};
  --light-3: #{$light-3-dark};
  --light-4: #{$light-4-dark};
  --light-5: #{$light-5-dark};
  --light-6: #{$light-6-dark};
  --light-7: #{$light-7-dark};
  --light-8: #{$light-8-dark};
  --light-9: #{$light-9-dark};

  /* Shadows */
  --shadow-small: #{$shadow-small-dark};
  --shadow-medium: #{$shadow-medium-dark};
  --shadow-large: #{$shadow-large-dark};
}
