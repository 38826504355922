.select {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  select {
    flex: 1;
    width: 100%;
    cursor: pointer;
    color: var(--dark);
    appearance: none;
    outline: none;
    font-family: inherit;

    background-color: var(--light);
    padding: 0.625rem 2.5rem 0.625rem 1.025rem;
    border: 1px solid var(--light-7);
    border-radius: var(--border-radius-small);
    transform: scale(0.9) translate3d(0, 0, 0);
    margin: -1% -5%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .icon {
    pointer-events: none;
    position: absolute;
    right: 0.875rem;
    width: 11px;
    height: auto;
    svg,
    g {
      fill: var(--dark-5);
    }
  }
}
