.group {
  &:not(:last-child) {
    margin-bottom: 0.65rem;
  }
}

.tab {
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 1.25rem;
  border-radius: var(--border-radius-small);
  border: 1px solid #eaeaea;
  box-shadow: var(--shadow-small);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: #fff;
  transition: background-color 0.15s;

  @media (hover: hover) {
    &:hover {
      background-color: #fafafa;
    }
  }
}

.date {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount {
  font-weight: 600;
  margin-left: auto;
  padding-left: 2rem;

  @media (max-width: 750px) {
    padding-left: 1.75rem;
  }
}

.inner {
  margin-top: -3px;
  padding: 1.25rem;
  padding-top: calc(1.25rem + 3px);
  border: 1px solid #eaeaea;
  border-top: none;
  border-radius: 0 0 4px 4px;
}
